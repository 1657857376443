import React from "react";
import {Create, SimpleForm, TextInput} from "react-admin";
import UserReviewTitle from "./UserReviewTitle";

const UserReviewCreate = props => (
    <Create title={<UserReviewTitle type="Create"/>} {...props}>
        <SimpleForm>
            <TextInput label={"User Name"} source={"name"} multiline fullWidth/>
            <TextInput label={"Review"} source={"review"} multiline fullWidth/>
        </SimpleForm>
    </Create>
);

export default UserReviewCreate;