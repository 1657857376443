import React from "react";
import {Create, PasswordInput, SimpleForm, TextInput} from "react-admin";
import UserCmsTitle from "./UserCmsTitle";

const UserCmsCreate = props => (
    <Create title={<UserCmsTitle type="Create"/>} {...props}>
        <SimpleForm>
            <TextInput label={"Email"} source={"email"} multiline/>
            <PasswordInput source={"password"} initiallyVisible={false}/>
        </SimpleForm>
    </Create>
);

export default UserCmsCreate;