import React from "react";
import {APP_CONSTANTS} from "../../providers/constants";
import {Login} from 'react-admin'
import {theme} from "../../theme/Theme";

export const LandingLoginPage = () => (
    <Login
        theme={theme}
        // A random image that changes everyday
        backgroundImage={APP_CONSTANTS.LOGIN_BACKGROUND}
    />
);