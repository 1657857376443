import UserSubscribeList from "./UserSubscribeList";
import UserSubscribeEdit from "./UserSubscribeEdit";
import UserSubscribeCreate from "./UserSubscribeCreate";
import CardMembershipIcon from '@material-ui/icons/CardMembership';

export default {
    list: UserSubscribeList,
    create: UserSubscribeCreate,
    edit: UserSubscribeEdit,
    icon: CardMembershipIcon
};