import UserReviewList from "./UserReviewList";
import UserReviewEdit from "./UserReviewEdit";
import UserReviewCreate from "./UserReviewCreate";
import RateReviewIcon from '@material-ui/icons/RateReview';

export default {
    list: UserReviewList,
    create: UserReviewCreate,
    edit: UserReviewEdit,
    icon: RateReviewIcon
};