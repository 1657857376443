import UserCmsList from "./UserCmsList";
import UserCmsEdit from "./UserCmsEdit";
import UserCmsCreate from "./UserCmsCreate";
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

export default {
    list: UserCmsList,
    create: UserCmsCreate,
    edit: UserCmsEdit,
    icon: SupervisorAccountIcon
};