import Popup from "reactjs-popup";
import React, {Component} from "react";

class ImageViewer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {url, width, height} = this.props;
        let image = new Image();
        image.src = url;
        return (
            <Popup
                trigger={
                    <img
                        alt={""}
                        src={url}
                        width={width}
                        height={height}
                        style={{display: "inline-block", padding: "13px"}}
                    />
                }
                position="left center"
                contentStyle={{
                    width: image.naturalWidth > 1280 ? 1280 : image.naturalWidth,
                    height: image.naturalHeight > 720 ? 720 : image.naturalHeight,
                }}
                modal
                closeOnDocumentClick
                closeOnEscape
            >
                <img src={url} width={image.naturalWidth > 1280 ? 1280 : image.naturalWidth}
                     height={image.naturalHeight > 720 ? 720 : image.naturalHeight} alt=""/>
            </Popup>
        )
    }
}

export default ImageViewer