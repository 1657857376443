import React from "react";
import {
    ArrayInput,
    Edit,
    FormTab,
    ReferenceInput,
    required,
    SelectInput,
    SimpleFormIterator,
    TabbedForm,
    TextInput
} from 'react-admin';
import CategoryTitle from "./CategoryTitle";
import {EditToolbar} from "../toolbar/ToolBar";
import {APP_CONSTANTS} from "../../providers/constants";

const CategoryEdit = (props) => {
    return (
        <Edit title={<CategoryTitle type={"Edit"}/>} {...props}>
            <TabbedForm redirect={false} toolbar={<EditToolbar/>}>
                <FormTab label={"Categories"}>
                    <ArrayInput label={"Translations"} source={"categoryTranslations"}>
                        <SimpleFormIterator>
                            <ReferenceInput label="Language Code" source="languageID"
                                            reference={APP_CONSTANTS.ROUTES.LANGUAGE}
                                            validate={[required()]}>
                                <SelectInput optionText="name"/>
                            </ReferenceInput>
                            <TextInput label={"Category Type"} source={"type"} fullWidth multiline
                                       validate={[required()]}/>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
};

//style={{display: "none"}}

export default CategoryEdit;