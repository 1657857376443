import CategoryList from "./CategoryList";
import CategoryEdit from "./CategoryEdit";
import CategoryCreate from "./CategoryCreate";
import CategoryIcon from '@material-ui/icons/Category';

export default {
    list: CategoryList,
    create: CategoryCreate,
    edit: CategoryEdit,
    icon: CategoryIcon
};