import React from "react";
import {Datagrid, DeleteWithConfirmButton, EditButton, ImageField, List, TextField} from 'react-admin'
import UserReviewTitle from "./UserReviewTitle";
import ListActions from "../../actions/list/list";

const UserReviewList = (props) => {
    return (
        <List bulkActionButtons={false} actions={<ListActions hasCreate/>}
              title={<UserReviewTitle type="User Review"/>} {...props}>
            <Datagrid>
                <TextField label="Username" source="name"/>
                <ImageField source="userImage.url" label="User Image"/>
                <EditButton label={""}/>
                <DeleteWithConfirmButton label={""}/>
            </Datagrid>
        </List>
    )
};

export default UserReviewList;