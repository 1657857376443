import React, {Component} from "react";
import {Edit, SimpleForm, TextInput} from 'react-admin';
import LanguageTitle from "./LanguageTitle";
import {EditToolbar} from "../toolbar/ToolBar";

class LanguageEdit extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;
        return (
            <Edit title={<LanguageTitle type={"Edit"}/>} {...props}>
                <SimpleForm redirect={false} toolbar={<EditToolbar/>}>
                    <TextInput label="Language Code" source="languageCode" disabled/>
                    <TextInput label="Name" source="name" disabled/>
                </SimpleForm>
            </Edit>
        )
    }
}

export default LanguageEdit;