import React from "react";
import {Create, email, required, SimpleForm, TextInput} from "react-admin";
import UserSubscribeTitle from "./UserSubscribeTitle";


const validateEmail = [email(), required()];

const UserSubscribeCreate = props => (
    <Create title={<UserSubscribeTitle type="Create"/>} {...props}>
        <SimpleForm>
            <TextInput label={"Email"} source={"email"} multiline validate={validateEmail}/>
        </SimpleForm>
    </Create>
);

export default UserSubscribeCreate;