import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import {APP_CONSTANTS} from "../../providers/constants";
import '../../css/Calendar.css';

const Dashboard = (props) => {
    return (
        <Card>
            <CardHeader title="Welcome to Hue Landing Trippal administration site"/>
            <CardContent>
                <a style={{color: "#12f6ff"}} href={`${APP_CONSTANTS.ROUTES.CMS_HUE_TRIPPAL}`} target="_blank">Cms
                    Hue
                    Trippal Site</a>
            </CardContent>
            <CardContent>
                <a style={{color: "#12f6ff"}} href={`${APP_CONSTANTS.ROUTES.HUE_TRIPPAL_LANDING}`} target="_blank">Hue
                    Trippal Landing Site</a>
            </CardContent>
        </Card>
    )
};

export default Dashboard;