import React from "react";
import {Create, ReferenceInput, required, SelectInput, SimpleForm, TextInput} from "react-admin";
import CategoryTitle from "./CategoryTitle";
import {APP_CONSTANTS} from "../../providers/constants";

const CategoryCreate = props => (
    <Create title={<CategoryTitle type="Create"/>} {...props}>
        <SimpleForm>
            <ReferenceInput label="Language Code" source="languageID" reference={APP_CONSTANTS.ROUTES.LANGUAGE}
                            validate={[required()]}>
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <TextInput label={"Category Type"} source={"type"} fullWidth multiline validate={[required()]}/>
        </SimpleForm>
    </Create>
);

export default CategoryCreate;