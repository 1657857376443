import {createMuiTheme} from '@material-ui/core/styles';

export const theme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#ffffff',
        },
        typography: {
            fontFamily: [
                'Times New Roman',
            ].join(','),
        },
        secondary: {
            light: '#000000',
            main: '#000000',
            contrastText: '#ffffff',//'#ffffff',
        },
    },
});