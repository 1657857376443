import LanguageList from "./LanguageList";
import LanguageEdit from "./LanguageEdit";
import LanguageCreate from "./LanguageCreate";
import LanguageIcon from '@material-ui/icons/Language';

export default {
    list: LanguageList,
    create: LanguageCreate,
    edit: LanguageEdit,
    icon: LanguageIcon
};