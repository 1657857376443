import React, {Component} from "react";
import {Datagrid, DeleteWithConfirmButton, EmailField, List} from 'react-admin'
import UserSubscribeTitle from "./UserSubscribeTitle";
import ListActions from "../../actions/list/list";

class UserSubscribeList extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;
        return (
            <List actions={<ListActions hasCreate/>} title={<UserSubscribeTitle type="User Subscription"/>} {...props}>
                <Datagrid>
                    <EmailField label="Email" source="email" style={{color: '#2deeff'}}/>
                    <DeleteWithConfirmButton label={""}/>
                </Datagrid>
            </List>

        )
    }
}

export default UserSubscribeList;