import React, {Component} from "react";
import {
    Datagrid,
    DeleteWithConfirmButton,
    EditButton,
    Filter,
    FunctionField,
    List,
    ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    TextField,
    TextInput
} from 'react-admin'
import NewsTitle from "./NewsTitle";
import ListActions from "../../actions/list/list";
import {APP_CONSTANTS} from "../../providers/constants";

const NewsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn/>
        <ReferenceInput label="Category" source="cateID" reference={APP_CONSTANTS.ROUTES.CATEGORY} allowEmpty={false}>
            <SelectInput optionText="categoryTranslations[0].type"/>
        </ReferenceInput>
    </Filter>
);


class NewsList extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;
        return (
            <List filters={<NewsFilter/>} actions={<ListActions hasCreate/>}
                  title={<NewsTitle type="News"/>} {...props}>
                <Datagrid>
                    <TextField label="Title" source="translations[0].title"/>
                    <TextField label="Available Languages" source="availableLanguages"/>
                    <ReferenceField label="Category" source="categoryID" reference={APP_CONSTANTS.ROUTES.CATEGORY}
                                    validate={[required()]}>
                        <TextField source="categoryTranslations[0].type"/>
                    </ReferenceField>
                    <FunctionField
                        label="Thumbnail"
                        render={record => {
                            if (!record.thumbnail) {
                                return null;
                            }
                            if (!record.thumbnail.url) {
                                return null;
                            }
                            if (record.thumbnail.url === "") {
                                return null
                            }
                            return (
                                <img
                                    alt={record.thumbnail.url}
                                    src={`${record.thumbnail.url}`}
                                    width={80}
                                    height={80}
                                />
                            );
                        }}
                    />
                    <EditButton label={""}/>
                    <DeleteWithConfirmButton label={""}/>
                </Datagrid>
            </List>

        )
    }
}

export default NewsList;