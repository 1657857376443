import React from "react";
import {Edit, PasswordInput, SimpleForm, TextInput} from 'react-admin';
import UserCmsTitle from "./UserCmsTitle";
import {EditToolbar} from "../toolbar/ToolBar";

const UserCmsEdit = (props) => {
    return (
        <Edit title={<UserCmsTitle type={"Edit"}/>} {...props}>
            <SimpleForm redirect={false} toolbar={<EditToolbar/>}>
                <TextInput label={"Email"} source={"email"} multiline/>
                <PasswordInput source={"password"} multiline/>
            </SimpleForm>
        </Edit>
    )
};

export default UserCmsEdit;