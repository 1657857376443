import React from "react";
import {
    ArrayInput,
    Edit,
    FormDataConsumer,
    FormTab,
    FunctionField,
    ImageField,
    ImageInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleFormIterator,
    TabbedForm,
    TextInput
} from 'react-admin';
import NewsTitle from "./NewsTitle";
import {EditToolbar} from "../toolbar/ToolBar";
import ImageViewer from "../image_viewer/ImageViewer";
import {APP_CONSTANTS} from "../../providers/constants";
import {styles} from "../../styles/styles";
import TinyMCEInput from "@palustris/ra-tinymce-input";
import {TinyMceInit} from "../tinymce_init/TinyMceInit";


const NewsEdit = (props) => {
    return (
        <Edit title={<NewsTitle type={"Edit"}/>} {...props}>
            <TabbedForm redirect={false} toolbar={<EditToolbar/>}>
                <FormTab label={"Summary"}>
                    <FunctionField label="Thumbnail" render={
                        record => record.thumbnail ?
                            <ImageViewer url={record.thumbnail.url} width={APP_CONSTANTS.IMAGES_WIDTH}
                                         height={APP_CONSTANTS.IMAGES_HEIGHT}/> : null
                    }/>
                    <ImageInput source="thumbnail" label="Thumbnail Uploader"
                                accept="image/*"
                                options={{
                                    //onDrop: acceptedFiles => this.onDrop(acceptedFiles, formData.id),
                                    //onRemove: (acceptedFiles) => this.onRemove(acceptedFiles.url, formData.id, showNotification, dataProvider, refreshView),
                                }}>
                        <ImageField source="url" title=""/>
                    </ImageInput>
                </FormTab>

                <FormTab label={"Content"}>
                    <ReferenceInput label="Category" source="categoryID" reference={APP_CONSTANTS.ROUTES.CATEGORY}
                                    validate={[required()]}>
                        <SelectInput optionText="categoryTranslations[0].type"/>
                    </ReferenceInput>
                    <ArrayInput source={"translations"}>
                        <SimpleFormIterator>
                            <ReferenceInput
                                label="Language" source={"languageID"}
                                reference={APP_CONSTANTS.ROUTES.LANGUAGE} validate={[required()]}>
                                <SelectInput optionText="name"/>
                            </ReferenceInput>
                            <TextInput source={"title"} label={"Title"} fullWidth multiline validate={[required()]}/>
                            <TextInput source={"shortDescription"} label={"Short Description"} fullWidth multiline/>
                            {/*<RichTextInput source="fullDescription" toolbar={toolbarOptions} />*/}
                            <FormDataConsumer>
                                {({formData, getSource}) => {
                                    //console.log("form", getSource("fullDescription"));
                                    if (formData) {
                                        return (<TinyMCEInput
                                            label={"Full Description"}
                                            source={getSource("fullDescription")}
                                            init={TinyMceInit(APP_CONSTANTS.ROUTES.NEWS, formData.id)}
                                        />)
                                    }
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>

                <FormTab label={"Images"}>
                    <ArrayInput label={"Images"} source={"images"} style={styles.images}>
                        <SimpleFormIterator disableAdd>
                            <FormDataConsumer>
                                {({scopedFormData}) => {
                                    if (!scopedFormData) {
                                        return null
                                    }
                                    return (
                                        <ImageViewer url={scopedFormData.url}
                                                     width={APP_CONSTANTS.IMAGES_WIDTH}
                                                     height={APP_CONSTANTS.IMAGES_HEIGHT}/>
                                    )
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ImageInput source="images" label="Images Uploader" accept="image/*" multiple
                                options={{
                                    //onDrop: acceptedFiles => this.onDrop(acceptedFiles, formData.id),
                                    //onRemove: (acceptedFiles) => this.onRemove(acceptedFiles.url, formData.id, showNotification, dataProvider, refreshView),
                                }}>
                        <ImageField style={{display: "none"}} source="url" title=""/>
                    </ImageInput>
                </FormTab>

            </TabbedForm>
        </Edit>
    )
};

//style={{display: "none"}}

export default NewsEdit;