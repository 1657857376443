import React from "react";
import {Edit, FunctionField, ImageField, ImageInput, SimpleForm, TextInput} from 'react-admin';
import UserReviewTitle from "./UserReviewTitle";
import {EditToolbar} from "../toolbar/ToolBar";
import {APP_CONSTANTS} from "../../providers/constants";
import ImageViewer from "../image_viewer/ImageViewer";

const UserReviewEdit = (props) => {
    return (
        <Edit title={<UserReviewTitle type={"Edit"}/>} {...props}>
            <SimpleForm redirect={false} toolbar={<EditToolbar/>}>
                <TextInput label="User Name" source="name" multiline fullWidth/>
                <TextInput label="Review" source="review" multiline fullWidth/>
                <FunctionField label="User Image" render={
                    record => record.userImage ?
                        <ImageViewer url={record.userImage.url} width={APP_CONSTANTS.IMAGES_WIDTH}
                                     height={APP_CONSTANTS.IMAGES_HEIGHT}/> : null
                }/>

                <ImageInput source="userImage" label="User Image Uploader"
                            accept="image/*">
                    <ImageField source="url" title=""/>
                </ImageInput>
            </SimpleForm>
        </Edit>
    )
};

export default UserReviewEdit;