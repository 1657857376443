import React, {Component} from "react";
import {Datagrid, List, TextField} from 'react-admin'
import LanguageTitle from "./LanguageTitle";
import ListActions from "../../actions/list/list";

class LanguageList extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;
        return (
            <List bulkActionButtons={false} actions={<ListActions hasCreate/>}
                  title={<LanguageTitle type="Language"/>} {...props}>
                <Datagrid>
                    <TextField label="Language Code" source="languageCode"/>
                    <TextField label="Name" source="name"/>
                </Datagrid>
            </List>

        )
    }
}

export default LanguageList;