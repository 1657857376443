import {stringify} from "query-string";
import {
    CREATE,
    DELETE,
    DELETE_MANY,
    fetchUtils,
    GET_LIST,
    GET_MANY,
    GET_MANY_REFERENCE,
    GET_ONE,
    UPDATE
} from "react-admin";
import React from "react";
import {APP_CONSTANTS} from "./constants";

export default function (apiUrl, httpClient = fetchUtils.fetchJson) {
    const convertDataRequestToHTTP = (type, resource, params) => {
        console.log("Type", type)
        let url = "";
        let options = {};
        options.user = {
            authenticated: true,
            token: "Bearer " + localStorage.getItem("token")
        };
        switch (type) {
            case GET_LIST: {
                const {page, perPage} = params.pagination;
                const {field, order} = params.sort;
                const query = {
                    ...fetchUtils.flattenObject(params.filter),
                    sort: field,
                    order: order,
                    page: page,
                    perPage: perPage
                };
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                options.method = "GET";
                break;
            }
            case GET_MANY: {
                const query = {
                    id: params.ids
                };
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                options.method = "GET";
                break;
            }

            case GET_ONE:
                console.log("params", params);
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = "GET";
                break;

            case GET_MANY_REFERENCE: {
                const {page, perPage} = params.pagination;
                const {field, order} = params.sort;
                const query = {
                    ...fetchUtils.flattenObject(params.filter),
                    [params.target]: params.id,
                    _sort: field,
                    _order: order,
                    _start: (page - 1) * perPage,
                    _end: page * perPage
                };
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                options.method = "GET";
                break;
            }

            case CREATE:
                url = `${apiUrl}/${resource}`;
                if (resource === 'user-cms') {
                    url = `${apiUrl}/${resource}/register`;
                }
                options.method = "POST";
                options.body = JSON.stringify(params.data);
                break;

            case UPDATE:
                //debugger
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = "PUT";
                if (resource === APP_CONSTANTS.ROUTES.SCREENSHOT) {
                    let form = new FormData();
                    for (let i = 0; i < params.data.images.length; i++) {
                        if (params.data.images[i].rawFile) {
                            form.append(`images[]`, params.data.images[i].rawFile);
                        } else {
                            form.append(`url[]`, params.data.images[i].url)
                        }
                    }
                    options.body = form;
                    break
                }

                if (resource === APP_CONSTANTS.ROUTES.USER_REVIEW) {
                    let form = new FormData();
                    //debugger
                    for (let key in params.data) {
                        if (params.data.hasOwnProperty(key)) {
                            if (key === 'userImage') {
                                if (params.data[key]) {
                                    if (params.data[key].rawFile) {
                                        form.append(`imageRaw`, params.data[key].rawFile);
                                    } else {
                                        form.append(`imageUrl`, params.data[key].url);
                                    }
                                }
                            } else {
                                form.append(`${key}`, params.data[key]);
                            }
                        }
                    }
                    options.body = form
                    break
                }

                if (resource === APP_CONSTANTS.ROUTES.NEWS) {
                    let form = new FormData();
                    for (let key in params.data) {
                        if (params.data.hasOwnProperty(key)) {
                            //console.log("Key", key, "->", "Value", params.data[key]);
                            if (key === 'thumbnail') {
                                if (params.data[key]) {
                                    if (params.data[key].rawFile) {
                                        form.append(`thumbnailRaw`, params.data[key].rawFile);
                                    } else {
                                        form.append(`thumbnailUrl`, params.data[key].url);
                                    }
                                }
                            }
                            if (key === 'images') {
                                if (params.data[key]) {
                                    params.data[key].map((item, i) => {
                                        if (params.data[key][i].rawFile) {
                                            form.append(`images[]`, params.data[key][i].rawFile);
                                        } else {
                                            form.append(`url[]`, params.data[key][i].url)
                                        }
                                    });
                                }
                            }
                            if (key === 'translations') {
                                form.append(`allTranslations`, JSON.stringify(params.data[key]));
                                // params.data[key].map((item, i) => {
                                // });
                            }
                            form.append(`${key}`, params.data[key]);
                        }
                    }
                    options.body = form;
                    break
                }
                options.body = JSON.stringify(params.data);
                break;

            case DELETE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = "DELETE";
                break;

            case DELETE_MANY:
                url = `${apiUrl}/${resource}`;
                options.method = "DELETE";
                break;
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
        return {url, options};
    };

    const convertHTTPResponse = (response, type, resource, params) => {
        const {headers, json} = response;
        //console.log("Type", type);
        //console.log("Response", response);
        //debugger
        switch (type) {
            case GET_ONE:
                return {
                    data: json.data ? json.data : null,
                };
            case GET_LIST:
                return {
                    data: json.data ? json.data : [],
                    total: json.data ? parseInt(headers.get("X-Total-Count")) : 0
                };
            case GET_MANY_REFERENCE:
                return {
                    data: json.data ? json.data : [],
                    total: json.data ? parseInt(headers.get("X-Total-Count")) : 0
                };
            case GET_MANY:
                return {
                    data: json.data ? json.data : [],
                    total: json.data ? parseInt(headers.get("X-Total-Count")) : 0
                };
            case CREATE:
                return {data: {...params.data, id: json.data.id}};
            case DELETE:
                return {data: {id: null}};
            default:
                return {data: json.data};
        }
    };

    return (type, resource, params) => {
        let {url, options} = convertDataRequestToHTTP(type, resource, params);
        if (resource === APP_CONSTANTS.ROUTES.SCREENSHOT && type === UPDATE) {
            if (params.data.images.length < 5) {
                return Promise.reject("The total number of screenshots must be greater than 4")
            }
        }
        // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        if (type === DELETE_MANY) {
            return Promise.all(
                params.ids.map(id => httpClient(`${apiUrl}/${resource}/${id}`, options))
            ).then(responses => ({
                data: responses.map(response => response.json)
            }));
        }
        return httpClient(url, options).then(response =>
            convertHTTPResponse(response, type, resource, params)
        );
    };
}

