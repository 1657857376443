import React, {Component} from "react";
import {Datagrid, DeleteWithConfirmButton, EditButton, List, TextField} from 'react-admin'
import CategoryTitle from "./CategoryTitle";
import ListActions from "../../actions/list/list";

class CategoryList extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;
        return (
            <List actions={<ListActions hasCreate/>} title={<CategoryTitle type="Category"/>} {...props}>
                <Datagrid>
                    <TextField label="Category Type" source="categoryTranslations[0].type"/>
                    <TextField label="Available Languages" source="availableLanguages"/>
                    <EditButton label={""}/>
                    <DeleteWithConfirmButton label={""}/>
                </Datagrid>
            </List>

        )
    }
}

export default CategoryList;