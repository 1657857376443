import React from "react";
import {Create, SimpleForm, TextInput} from "react-admin";
import LanguageTitle from "./LanguageTitle";

const LanguageCreate = props => (
    <Create title={<LanguageTitle type="Create"/>} {...props}>
        <SimpleForm>
            <TextInput label={"Language Code"} source={"languageCode"}/>
        </SimpleForm>
    </Create>
);

export default LanguageCreate;