import React from "react";
import {Create, ReferenceInput, required, SelectInput, SimpleForm, TextInput} from "react-admin";
import NewsTitle from "./NewsTitle";
import {APP_CONSTANTS} from "../../providers/constants";

const NewsCreate = props => (
    <Create title={<NewsTitle type="Create"/>} {...props}>
        <SimpleForm>
            <ReferenceInput label="Language Code" source="languageID" reference={APP_CONSTANTS.ROUTES.LANGUAGE}
                            validate={[required()]}>
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <ReferenceInput label="Category" source="categoryID" reference={APP_CONSTANTS.ROUTES.CATEGORY}
                            validate={[required()]}>
                <SelectInput optionText="categoryTranslations[0].type"/>
            </ReferenceInput>
            <TextInput label={"Title"} source={"title"} fullWidth multiline validate={[required()]}/>
        </SimpleForm>
    </Create>
);

export default NewsCreate;