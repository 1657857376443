export const APP_CONSTANTS = {
    API_URL: process.env.REACT_APP_MODE === 'prod' ?
        process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_LOCAL,
    RESOURCES: process.env.REACT_APP_MODE === 'prod' ?
        process.env.REACT_APP_SERVER_IMAGE_PROD : process.env.REACT_APP_SERVER_IMAGE_LOCAL,
    ROUTES: {
        DOWNLOAD: 'download',
        FEATURE: 'feature',
        SOCIAL_MEDIA: 'social-media',
        SCREENSHOT: 'screenshot',
        USER_CMS: 'user-cms',
        LATEST_NEWS: 'latest-news',
        NEWS: 'news',
        LANGUAGE: 'language',
        USER_SUBSCRIPTION: 'user-subscription',
        USER_REVIEW: 'user-review',
        CATEGORY: 'category',
        CMS_HUE_TRIPPAL: 'https://cms.huetrippal.com',
        HUE_TRIPPAL_LANDING: 'https://huetrippal.com/',
        ABOUT_US: 'aboutUs',
        INQUIRIES: 'inquiries'
    },
    NEWS_CATEGORY: [
        {id: 'Insights', name: 'Insights'},
        {id: 'Customer Success Stories', name: 'Customer Success Stories'},
        {id: 'Technology & Innovations', name: 'Technology & Innovations'},
        {id: 'Partner Success Stories', name: 'Partner Success Stories'},
    ],
    TINY_WIDTH: 1366,
    TINY_HEIGHT: 720,
    IMAGES_WIDTH: 170,
    IMAGES_HEIGHT: 130,
    ARTICLE_IMAGE_WIDTH: 320,
    ARTICLE_IMAGE_HEIGHT: 320,
    LOGIN_BACKGROUND: 'https://resources.huetrippal.com/resources/places/5ceba9069373fa376c75422e/images/037d0677-b7d7-4425-b518-f929afd4a173.jpg',
};