import React, {Component} from "react";
import {Datagrid, EditButton, EmailField, List} from 'react-admin'
import UserCmsTitle from "./UserCmsTitle";
import ListActions from "../../actions/list/list";

class UserCmsList extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;
        return (
            <List bulkActionButtons={false} actions={<ListActions hasCreate/>}
                  title={<UserCmsTitle type="User Admin"/>} {...props}>
                <Datagrid>
                    <EmailField style={{color: '#3fc1ff'}} label={"Email"} source="email"/>
                    <EditButton label={""}/>
                </Datagrid>
            </List>

        )
    }
}

export default UserCmsList;