import React from 'react';
import './App.css';
import {APP_CONSTANTS} from "./providers/constants";
import {Admin} from "react-admin";
import dashboard from './components/dashboard'
import {ShowResources} from "./components/resources/resources";
import myDataProvider from "./providers/myDataProvider";
import authProvider from "./providers/authProvider";
import {LandingLoginPage} from "./components/login_page/LandingLoginPage";
import {theme} from "./theme/Theme";

const dataProvider = myDataProvider(APP_CONSTANTS.API_URL);

class App extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Admin
                locale="en"
                dashboard={dashboard}
                theme={theme}
                dataProvider={dataProvider}
                authProvider={authProvider}
                loginPage={LandingLoginPage}
            >
                {ShowResources()}
            </Admin>
        )
    }
}

export default App;
