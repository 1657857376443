import React, {Component} from "react";
import {Edit, EmailField, SimpleForm} from 'react-admin';
import UserSubscribeTitle from "./UserSubscribeTitle";
import {NoButtonToolbar} from "../toolbar/ToolBar";

class UserSubscribeEdit extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;
        return (
            <Edit title={<UserSubscribeTitle type={"Edit"}/>} {...props}>
                <SimpleForm redirect={false} toolbar={<NoButtonToolbar/>}>
                    <EmailField label="Email" source="email" style={{color: '#2deeff'}}/>
                </SimpleForm>
            </Edit>
        )
    }
}

export default UserSubscribeEdit;