import React from "react";
import user_subscribe from '../user_subscribe'
import news from '../news'
import category from '../news_category'
import user_cms from '../user_cms'
import user_review from '../user_review'
import languages from '../languages'
import {Resource} from "react-admin";
import {APP_CONSTANTS} from "../../providers/constants";

const displayLabel = name => ({label: name});

export const ShowResources = () => {
    return (
        [
            <Resource
                name={APP_CONSTANTS.ROUTES.USER_CMS}
                options={displayLabel("Admin Users")}
                {...user_cms}
            />,
            // <Resource
            //     name={APP_CONSTANTS.ROUTES.DOWNLOAD}
            //     options={displayLabel("Download Description")}
            //     {...download}
            // />,
            // <Resource
            //     name={APP_CONSTANTS.ROUTES.FEATURE}
            //     options={displayLabel("Feature Description")}
            //     {...feature}
            // />,
            // <Resource
            //     name={APP_CONSTANTS.ROUTES.LATEST_NEWS}
            //     options={displayLabel("Latest News Description")}
            //     {...latest_news}
            // />,
            <Resource
                name={APP_CONSTANTS.ROUTES.NEWS}
                options={displayLabel("News")}
                {...news}
            />,
            <Resource
                name={APP_CONSTANTS.ROUTES.CATEGORY}
                options={displayLabel("News Category")}
                {...category}
            />,
            // <Resource
            //     name={APP_CONSTANTS.ROUTES.SCREENSHOT}
            //     options={displayLabel("Screenshot")}
            //     {...screenshot}
            // />,
            <Resource
                name={APP_CONSTANTS.ROUTES.USER_SUBSCRIPTION}
                options={displayLabel("Subscription")}
                {...user_subscribe}
            />,
            <Resource
                name={APP_CONSTANTS.ROUTES.USER_REVIEW}
                options={displayLabel("User Review")}
                {...user_review}
            />,
            // <Resource
            //     name={APP_CONSTANTS.ROUTES.SOCIAL_MEDIA}
            //     options={displayLabel("Social Media")}
            //     {...social_media}
            // />,
            <Resource
                name={APP_CONSTANTS.ROUTES.LANGUAGE}
                options={displayLabel("Languages")}
                {...languages}
            />,
            //     <Resource
            //         name={APP_CONSTANTS.ROUTES.ABOUT_US}
            //         options={displayLabel("About Us")}
            //         {...aboutUs}
            //     />,
            //     <Resource
            //         name={APP_CONSTANTS.ROUTES.INQUIRIES}
            //         options={displayLabel("Inquiries")}
            //         {...inquiry}
            //     />,
        ]
    )
};