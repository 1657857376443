import NewsList from "./NewsList";
import NewsEdit from "./NewsEdit";
import NewsCreate from "./NewsCreate";
import AnnouncementIcon from '@material-ui/icons/Announcement';

export default {
    list: NewsList,
    create: NewsCreate,
    edit: NewsEdit,
    icon: AnnouncementIcon
};